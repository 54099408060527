<template>
  <div class="modal fade" tabindex="-1" role="dialog" ref="mdlDetallesProveedor">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="p-sm text-center bg-info">
          <span style="font-size:30px"><strong>Detalles del proveedor</strong></span>
        </div>
        
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12">
              <div class="table-responsive">
                <table class="table table-hover">
                  <tbody>
                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-building fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        {{proveedor.nombre}}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-user-o fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        {{proveedor.rfc}}
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-map-marker fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        <b>Calle</b> {{ proveedor.calle }}
                        <span v-if="!atributoVacio(proveedor.numero_exterior)">
                          , <b>Num Ext</b> {{ proveedor.numero_exterior }}
                        </span>
                        <span v-if="!atributoVacio(proveedor.numero_interior)">
                        , <b>Num Int</b> {{ proveedor.numero_interior }}
                        </span>
                        <br />

                        <span v-if="!atributoVacio(proveedor.colonia)">
                        <b>Colonia</b> {{ proveedor.colonia }}
                        </span>
                        <span v-if="!atributoVacio(proveedor.municipio)">
                        ,<b>Municipio</b> {{ proveedor.municipio }}
                        </span>
                        <br />

                        <span v-if="!atributoVacio(proveedor.codigo_postal)">
                        <b>Código postal</b> {{ proveedor.codigo_postal }}
                        </span>
                        <span v-if="!atributoVacio(proveedor.estado)">
                        ,<b>Estado</b> {{ proveedor.estado }}
                        </span>
                        <span v-if="!atributoVacio(proveedor.pais)">
                        ,<b>País</b> {{ proveedor.pais }}
                        </span>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-envelope-o fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        {{proveedor.correo}}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-phone fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        {{ cadenaTelefonosProveedor() }}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-cc-mastercard fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        <b>Datos para transferencia bancaria</b><br>
                        {{proveedor.transferencia_bancaria}}
                      </td>
                    </tr>

                    <tr>
                      <td style="width: 40px" class="text-center">
                        <i class="fa fa-cc-mastercard fa-2x color-verde"></i>
                      </td>
                      <td style="width: 100px">
                        <b>Datos para depósito</b><br>
                        {{proveedor.datos_deposito}}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 text-center">
              <button type="button" class="btn btn-outline btn-primary" data-dismiss="modal">
                <i class="fa fa-check"></i>
                OK
              </button>
            </div>
          </div>
        </div>


      </div>
      <!-- /.modal-content -->
    </div>
    <!-- /.modal-dialog -->
  </div>
  <!-- /.modal -->
</template>

<script>

export default {
  data: function() {
    return {
      proveedor_correcto: {
        nombre: '',
        rfc: '',
        calle: '',
        numero_exterior: '',
        numero_interior: '',
        colonia: '',
        municipio: '',
        codigo_postal: '',
        estado: '',
        pais: '',
        correo: '',
        telefonos: [],
        transferencia_bancaria: '',
        datos_deposito: '',
        familias:[],
        proveedor_principal: false
      },
      proveedor: {
        nombre: 'URREA',
        rfc: 'Ferreteria Urrea S. de R.L. de C.V.',
        calle: 'Av. Tuxpan',
        numero_exterior: '23',
        numero_interior: '',
        colonia: 'San Martin Centro',
        municipio: 'San Martin de las Piramides',
        codigo_postal: '55850',
        estado: 'Estado de México',
        pais: 'México',
        correo: 'ferreteria_urrea@emial.com',
        telefonos: [
          {
            tipo: 'celular',
            numero: '5536469157'
          },
          {
            tipo: 'celular',
            numero: '5536469157'
          },
          {
            tipo: 'celular',
            numero: '5536469157'
          }
        ],
        transferencia_bancaria: 'NOTA: Agregar cuenta para transferencias o dejar en blanco en caso de que el pago sea en efectivo',
        datos_deposito: 'NOTA: Agregar cuenta para transferencias o dejar en blanco en caso de que el pago sea en efectivo',
        familias:[],
        proveedor_principal: false
      }
    }
  },
  methods: {
    atributoVacio: function(atributo) {
      if (atributo == '' || atributo == null || atributo == undefined)
        return true
      return false
    },

    cadenaTelefonosProveedor: function() {
      var proveedor = this.proveedor
      var cadena = ''

      proveedor.telefonos.forEach(function(telefono, index) {
        cadena += telefono.numero

        if (index + 1 < proveedor.telefonos.length) {
          cadena += ', '
        }
      })

      return cadena
    },

    mostrar(articulo) {
      var self = this
      self.id_articulo = articulo.id
      $(this.$refs.mdlDetallesProveedor).modal("show")
      //self.cargarArticulo()
    },

    cargarArticulo: function() {
      
    }
  }
}
</script>


<style scoped>
.color-verde {
  color: #1AB394
}
</style>
